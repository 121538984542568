import { defineStore } from "pinia/dist/pinia";
import { getAxios } from "@/axios/axios";
import { ref } from "vue";
import jwtDecode from "jwt-decode";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    isLoggedIn: ref(false),
    latestActAt: ref(null),
    auth: ref({}),
    redirectUrl: null,
  }),
  getters: {
    jti(state) {
      if (state.isLoggedIn) {
        return state.auth["jti"];
      }
      return null;
    },
    accessToken(state) {
      if (state.isLoggedIn) {
        return state.auth["access_token"];
      }
      return null;
    },
    tokenExpireIn(state) {
      if (state.isLoggedIn) {
        return state.auth["expires_in"];
      } else {
        return null;
      }
    },
    tokenExpireAt(state) {
      const decodedToken = jwtDecode(state.accessToken);
      return decodedToken["exp"];
    },
  },
  actions: {
    async signIn(userId, password) {
      console.log(userId);
      console.log(password);
      try {
        await getAxios()
          .post(
            "/auth/login",
            {
              userId: userId,
              password: password,
            },
            {
              headers: { "Content-Type": "application/json" },
            },
          )
          .then(res => {
            if (res.data.status === 200) {
              sessionStorage.clear();
              console.log("성공");
              console.log(res.data.content);
              this.isLoggedIn = true;
              this.auth = res.data.content;
              this.latestActAt = String(new Date().getTime() / 1000);
            }
          })
          .catch(err => {
            console.error(err.message);
          });
      } catch (e) {
        console.error(e.message);
      }
    },
    checkToken() {
      if (!this.isLoggedIn) {
        return false;
      }
      try {
        // 토큰이 유효한지 검사
        // 유효할경우 true
        // 유효하지 않을 경우
        //    - 갱신가능한지 (마지막 활동시간이 토큰유효기간(초) 이내이면 갱신하고 true
        //    - 만료 false
        const currentAt = Number(new Date().getTime() / 1000); // 현재시간
        const tokenExpireAt = Number(this.tokenExpireAt); // 토큰 만료 예정
        if (tokenExpireAt > currentAt) {
          // 갱신하지 않아도 됨.
          console.log("토큰갱신 불필요");
          return true;
        } else {
          const latestActAt = Number(sessionStorage.getItem("latestActAt")); // 마지막 활동시간
          const tokenExpireIn = Number(this.tokenExpireIn); // 유효기간 (초)
          const refreshAllowAt = latestActAt + tokenExpireIn;
          if (refreshAllowAt > currentAt) {
            // 갱신
            this.refreshAuth();
            return true;
          } else {
            // 자동 로그아웃
            console.log("부재중으로 자동 로그아웃");
            this.logout();
            return false;
          }
        }
      } catch (ex) {
        console.log(ex);
        this.logout();
        return false;
      }
    },
    refreshAuth() {
      try {
        const refreshToken = this.refreshToken;
        getAxios()
          .post(
            "/auth/refresh-token",
            { refreshToken: refreshToken },
            { headers: { "Content-Type": "application/json" } },
          )
          .then(res => {
            if (res.data.status === 200) {
              console.log("성공" + res);
              this.isLoggedIn = true;
              this.auth = res.data.content;
              this.latestActAt = String(new Date().getTime() / 1000);
            } else {
              console.error("실패");
              this.logout();
            }
          })
          .catch(ex => {
            console.error("실패" + ex.message);
          });
      } catch (ex) {
        console.error(ex);
        return false;
      }
    },
    logout() {
      sessionStorage.clear();
      this.isLoggedIn = false;
      this.latestActAt = "";
      this.auth = {};
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "authorize",
        storage: sessionStorage,
      },
    ],
  },
});
