import LayoutMain from "@/layouts/LayoutMain.vue";
import LayoutNaked from "@/layouts/LayoutNaked.vue";

const routes = [
  {
    path: "/",
    component: LayoutMain,
    children: [
      {
        path: "/",
        name: "DASHBOARD",
        component: () => import("@/pages/dashboard/Dashboard.vue"),
        meta: {
          title: "대시보드",
        },
      },
      // 거래조회
      {
        path: "/payments/bank/list",
        name: "PAYMENTS_BANK_LIST",
        component: () => import("@/pages/payments/PaymentsBankList"),
        meta: {
          title: "계좌이체 거래조회",
        },
      },
      {
        path: "/payments/card/list",
        name: "PAYMENTS_CARD_LIST",
        component: () => import("@/pages/payments/PaymentsCardList"),
        meta: {
          title: "카드결제 거래조회",
        },
      },
      {
        path: "/payments/cash/list",
        name: "PAYMENTS_CASH_LIST",
        component: () => import("@/pages/payments/PaymentsCashList"),
        meta: {
          title: "현금영수증 거래조회",
        },
      },
      {
        path: "/payments/detail",
        name: "PAYMENTS_DETAIL",
        component: () => import("@/pages/payments/PaymentsDetail"),
        meta: {
          title: "결제 상세정보",
        },
      },
      // 정산관리
      {
        path: "/settlement/merchant/list",
        name: "SETTLEMENT_MERCHANT_LIST",
        component: () => import("@/pages/settlement/SettlementMerchantList"),
        meta: {
          title: "정산내역",
        },
      },
      {
        path: "/settlement/merchant/holdList",
        name: "SETTLEMENT_MERCHANT_HOLD_LIST",
        component: () => import("@/pages/settlement/merchantHoldList"),
        meta: {
          title: "정산 보류내역",
        },
      },
      {
        path: "/settlement/merchant/childList",
        name: "SETTLEMENT_MERCHANT_CHILD_LIST",
        component: () => import("@/pages/settlement/merchantChildList"),
        meta: {
          title: "하위몰 정산",
        },
      },
      // 영업점관리
      {
        path: "/branch/list",
        name: "BRANCH_LIST",
        component: () => import("@/pages/branch/BranchList"),
        meta: {
          title: "영업점 목록",
        },
      },
      {
        path: "/branch/sales",
        name: "BRANCH_SALES",
        component: () => import("@/pages/branch/BranchSales"),
        meta: {
          title: "영업점 매출",
        },
      },
      {
        path: "/branch/sales/history/:userSeq/:orgSeq/:inquiryStartDate/:inquiryEndDate/:page?",
        name: "BRANCH_SALES_HISTORY",
        component: () => import("@/pages/branch/BranchSalesHistory"),
        meta: {
          title: "영업담당자별 매출",
        },
      },
      {
        path: "/branch/sales/detail/:tranSeq",
        name: "BRANCH_SALES_DETAIL",
        component: () => import("@/pages/branch/BranchSalesDetail"),
        meta: {
          title: "결제상세정보",
        },
      },
      // 결제
      {
        path: "/pay/card",
        name: "PAY_CARD",
        component: () => import("@/pages/pay/PayCard"),
        meta: {
          title: "신용카드 결제",
        },
      },
      {
        path: "/pay/sms",
        name: "PAY_SMS",
        component: () => import("@/pages/pay/PaySms"),
        meta: {
          title: "톡결제",
        },
      },
      {
        path: "/pay/sms/list",
        name: "PAY_SMS_LIST",
        component: () => import("@/pages/pay/PaySmsList"),
        meta: {
          title: "톡결제",
        },
      },
      {
        path: "/pay/sms/detail/:requestId/:requestKey",
        name: "PAY_SMS_DETAIL",
        component: () => import("@/pages/pay/PaySmsDetail"),
        meta: {
          title: "톡결제 상세",
        },
      },
      // 약관
      {
        path: "/vatreport",
        name: "INFO_VATREPORT",
        component: () => import("@/pages/info/VATReport"),
        meta: {
          title: "부가세 자료 조회",
        },
      },
      // 약관
      {
        path: "/mypage",
        name: "INFO_MYPAGE",
        component: () => import("@/pages/info/Mypage"),
        meta: {
          title: "마이페이지",
        },
      },
      {
        path: "/notice/list",
        name: "INFO_NOTICE_LIST",
        component: () => import("@/pages/info/NoticeList"),
        meta: {
          title: "공지사항",
        },
      },
      // 약관
      {
        path: "/terms/use",
        name: "TERMS_USE",
        component: () => import("@/pages/terms/TermsUse.vue"),
        meta: {
          title: "이용약관",
        },
      },
      {
        path: "/terms/privacy",
        name: "TERMS_PRIVACY",
        component: () => import("@/pages/terms/TermsPrivacy.vue"),
        meta: {
          title: "개인정보 처리방침",
        },
      },
      {
        path: "/terms/payments",
        name: "TERMS_PAYMENTS",
        component: () => import("@/pages/terms/TermsPayments.vue"),
        meta: {
          title: "전자금융거래 기본약관",
        },
      },
    ],
  },
  {
    path: "/",
    component: LayoutNaked,
    children: [
      {
        path: "/login",
        name: "LOGIN",
        component: () => import("@/pages/auth/Login.vue"),
      },
      {
        path: "/findid",
        name: "FIND_ID",
        component: () => import("@/pages/auth/FindId.vue"),
      },
      {
        path: "/findpw",
        name: "FIND_PW",
        component: () => import("@/pages/auth/FindPw.vue"),
      },
      {
        path: "/404",
        name: "NOT_FOUND",
        component: () => import("@/components/error/NotFound.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
      },
    ],
  },
];
export default routes;
