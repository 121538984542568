import { defineStore } from "pinia/dist/pinia";
import { ref } from "vue";
import { getAxios } from "@/axios/axios";

export const useProfileStore = defineStore("profileStore", {
  state: () => ({
    role: ref(null),
    businessName: ref(null),
    settlementCycleName: ref(null),
    basic: ref({
      "userSeq": "",
      "userId": "",
      "userPhoneNumber": "",
      "joinDate": "",
      "email": "",
      "userStatus": ""
    }),
    company: ref({
      "agentName": "",
      "companyTypeName": "",
      "ceoName": "",
      "businessName": "",
      "businessNumber": "",
      "businessTypeCate": "",
      "servicesTypeName": "",
      "zipCode": "",
      "addressMain": "",
      "addressDetail": "",
      "businessSize": "",
      "ceoMobilePhoneNumber":"",
      "merchCate":"",
    }),
    settlement: ref({
      "settlementCycleName": "",
      "bankCode": "",
      "bankCodeName": "",
      "bankAccountNumber": "",
      "bankAccountHolder": ""
    }),
    paymentItems: ref([
      {
        "serviceItem": "",
        "serviceItemName": "",
        "merchantCommission": "",
        "updateDateTime": ""
      },
    ]),
    latestActAt: ref(null)
  }),
  getters: {
    roleName(state) {
      return state.role;
    },
    bizName(state) {
      return state.businessName;
    },
    stlmCycle(state) {
      return state.settlementCycleName;
    },
    basicInfo(state) {
      return state.basic;
    },
    companyInfo(state) {
      return state.company;
    },
    settlementInfo(state) {
      return state.settlement;
    },
    paymentItemList(state) {
      return state.paymentItems;
    }
  },
  actions: {
    async setProfile() {
      let bRetrieve = false;
      if (this.latestActAt == null || this.role == null) {
        bRetrieve = true;
      }
      if (bRetrieve === false) {
        if (this.latestActAt + 300 < new Date().getTime() / 1000) {
          bRetrieve = true;
        }
      }
      if (bRetrieve) {
        this.latestActAt = new Date().getTime() / 1000;
        await getAxios(true)
          .get("/users/me")
          .then(res => {
            if (res.data.status === 200) {
              console.log("성공");
              this.role = res.data.content.role;
              this.businessName = res.data.content.businessName;
              this.settlementCycleName = res.data.content.settlementCycleName;
            }
          })
          .catch(reason => {
            console.error(reason.message);
          });

        await getAxios(true)
          .get("/me")
          .then(res => {
            if (res.data.status === 200) {
              console.log(res.data.content);
              this.basic = res.data.content.basic;
              this.company = res.data.content.company;
              this.settlement = res.data.content.settlement;
              this.paymentItems = res.data.content.paymentItems;
            }
          })
          .catch(reason => {
            console.error(reason.message);
          });
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "profile",
        storage: sessionStorage,
      },
    ],
  },
});
